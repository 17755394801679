import React, { Component } from 'react';
import Logo from '../images/logo/flick_logo_type_dark.svg';
import { Link } from 'react-router-dom';

export default class NavBar extends Component {
    state = {
        isOpen: false
    }

    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {

        const barStyle = {
            top: 0,
            left: 0,
            width: "100%",
            padding: "2rem 0",
        }

        const headerStyle = {
            display: "flex",
        }

        return (
            <nav style={barStyle}>
            {/* <nav className="nav-bar"> */}
                <div style={headerStyle}>
                    <Link to="/">
                        <img src={Logo} alt="Flick Logo" className="flick-logo" />
                    </Link>
                </div>
            </nav>
        )
    }

}