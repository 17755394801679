import React, { Component } from 'react';
import "./App.css";
import { Route, Switch } from 'react-router-dom';
import { Desktop, Mobile } from './Utilities/Breakpoints';
import HeroVideo from './images/flick_animation_60fps.mp4';

// Components
import NavBar from './components/NavBar';
import Home from './components/Home';
import Footer from './components/Footer';

const wrapper = {
    height: "100vh",
    width: "100%",
    position: "relative",
}

// Creates a containing box for the landing page.
const landingContainer = {
    height: "100%",
    width: "100%",
    padding: "0 6%",
    minHeight: "800px",
}

const landingContainerMobile = {
    width: "100%",
    padding: "0 6%",
    textAlign: "center",
    minHeight: "750px",
    maxHeight: "1000px",
    position: "relative",
}

// Creates a flex box for the landing page items.
const landingFlexer = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
}

const bodyStyle = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
}

const videoTag = {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "63vw",
    zIndex: -1,
}

const videoTagMobile = {
    position: "absolute",
    bottom: -1,
    right: 0,
    width: "110%",
    zIndex: -1,
}

export default function App() {

    return (
        <>
            <Desktop>
                <div style={wrapper}>
                    <div style={landingContainer}>
                        <div style={landingFlexer}>
                            <div style={bodyStyle}>
                                <NavBar />
                                <div style={{ flexGrow: 1 }} />
                                <Switch>
                                    <Route component={Home} />
                                </Switch>
                                <div style={{ flexGrow: 1.2 }} />
                            </div>
                        </div>
                        <video style={videoTag} preload="auto" autoPlay loop muted playsInline>
                            <source src={HeroVideo} type='video/mp4' />
                        </video>
                    </div>
                </div>
                <Footer />
            </Desktop>

            <Mobile>
                <div style={landingContainerMobile}>
                    <div style={landingFlexer}>
                        <div style={bodyStyle}>
                            <NavBar />
                            <div style={{ flexGrow: 1 }} />
                            <Switch>
                                <Route component={Home} />
                            </Switch>
                            <div style={{ flexGrow: 10 }} />
                        </div>
                    </div>
                    <video style={videoTagMobile} preload="auto" autoPlay loop muted playsInline>
                        <source src={HeroVideo} type='video/mp4' />
                    </video>
                </div>
                <Footer />
            </Mobile>
        </>
    );

}