import React from 'react';
import LogoLight from '../images/logo/flick_logo_type_light.svg';
import Social from '../components/Social';
import { Desktop, Mobile } from '../Utilities/Breakpoints';

export default function Footer() {

    const footerStyleDesktop = {
        fontFamily: "CircularStd, Arial, sans-serif",
        fontStyle: "normal",
        fontSize: "14px",
        color: "white",
        backgroundColor: "#00113C",
        padding: "88px 6% 0 6%",
        width: "100%",
        height: "271px",
    };

    const footerStyleMobile = { ...footerStyleDesktop };
    footerStyleMobile.padding = "56px 6% 0 6%",
    footerStyleMobile.height = "376px";

    const parStyle = {
        opacity: "0.5",
        letterSpacing: "-0.5px",
        padding: "27px 0 0 0"
    };

    const parStyleMobile = { ...parStyle }
    parStyleMobile.padding = "51px 0 0 0"

    return (
        <>
            <Desktop>
                <div style={footerStyleDesktop}>
                    <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                        <img src={LogoLight} alt="Flick Logo" className="flick-logo" />
                        <Social />
                    </div>
                    <p style={parStyle}>Flick is a product of ydangle apps. ©2019. All rights reserved. This app is protected by SA Patent application No. 2013/02981</p>
                </div>
            </Desktop>
            <Mobile>
                <div style={footerStyleMobile}>
                    <img src={LogoLight} alt="Flick Logo" className="flick-logo" style={{padding: "0 0 56px 0"}}/>
                    <Social />
                    <p style={parStyleMobile}>Flick is a product of ydangle apps. ©2019. All rights reserved. This app is protected by SA Patent application No. 2013/02981</p>
                </div>
            </Mobile>
        </>
    )
}