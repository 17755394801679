export default [
    {
        id: 'android',
        link: "https://play.google.com/store/apps/details?id=com.ydangleapps.flickrn",
        betaLink: "https://play.google.com/apps/testing/com.ydangleapps.flickrn",
        // onLinkClick: e => alert("The Android version is coming soon!"),
        // onBetaLinkClick: e => alert("The Android beta version is coming soon!"),
        title: "Android",
        subtitle: 'Android 5 and up',
        logo: require('../images/platforms/platform_android.svg')
    },
    {
        id: 'ios',
        link: "https://itunes.apple.com/us/app/flick./id644265534",
        betaLink: "https://testflight.apple.com/join/2yuGwRmQ",
        // onBetaLinkClick: e => alert("The iOS beta version is coming soon!"),
        title: "iOS",
        subtitle: 'iPhone, iPad',
        logo: require('../images/platforms/platform_apple.svg')
    },
    {
        id: 'windowsLegacy',
        link: "https://ydangleapps.s3.amazonaws.com/flick-windows-installer.exe",
        title: "Windows",
        subtitle: "XP, Vista, 7, 8, 10+",
        logo: require('../images/platforms/platform_windows.svg')
    },
    {
        id: 'windowsUniversal',
        link: "https://www.microsoft.com/en-us/p/flick/9wzdncrd8b38",
        title: "Windows 10",
        subtitle: "Windows 10 and up",
        logo: require('../images/platforms/platform_windows.svg')
    },
    {
        id: 'macOS',
        link: "https://itunes.apple.com/us/app/flick./id646740026",
        title: "macOS",
        subtitle: 'OS X 10.7 or later',
        logo: require('../images/platforms/platform_apple.svg')
    },
    {
        id: 'linux',
        link: "https://s3-us-west-2.amazonaws.com/getflick.io/Flick.1.0.3.zip",
        title: "Linux",
        subtitle: 'Java based',
        logo: require('../images/platforms/platform_linux.svg')
    },
    // "beta-android": {
    //     link: "https://play.google.com/apps/testing/com.ydangleapps.Flick",
    //     title: "Android Beta",
    // },
    // "beta-ios": {
    //     link: "https://testflight.apple.com/join/2yuGwRmQ",
    //     title: "iOS Beta",
        // logo: require('../images/platforms/platform_apple.svg')
    // }
]