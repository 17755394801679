import React from 'react';
import SocialEmail from '../images/social/social_email.svg';
import SocialTwitter from '../images/social/social_twitter.svg';
import SocialLinkedIn from '../images/social/social_linked-in.svg';
import SocialFacebook from '../images/social/social_facebook.svg';

export default function Social() {
    return (
        <div>
            <a href="mailto:info@ydangleapps.com" target="new" title="email" class="social-icon">
                <img src={SocialEmail} width="32" />
            </a>
            <a href="https://twitter.com/get_flick" target="new" title="Twitter" class="social-icon">
                <img src={SocialTwitter} width="32" />
            </a>
            <a href="https://za.linkedin.com/company/ydangle-apps-pty-ltd" target="new" title="LinkedIn" class="social-icon">
                <img src={SocialLinkedIn} width="32" />
            </a>
            <a href="https://www.facebook.com/getflicknow" target="new" title="Facebook" class="social-icon">
                <img src={SocialFacebook} width="32" />
            </a>
        </div>
    );
}