import React, { Component } from 'react';
import FlickDotLogo from '../images/logo/flick_logo_dot.svg';
import detectOS from '../Utilities/OSDetector';
import PlatformInfo from '../models/Platforms';
import PlatformList from './PlatformList';
import { Desktop, Mobile } from '../Utilities/Breakpoints';

class Home extends Component {

    // Initial state
    state = {

        // Detect initially selected platform
        platform: PlatformInfo.find(p => p.id == detectOS()) || PlatformInfo.find(p => p.id == 'windowsLegacy')

    }

    render() {

        const headingStyleDesktop = {
            fontFamily: "CircularStd, Arial, sans-serif",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "4.5rem",
            padding: "0rem",
            lineHeight: "71px",
            letterSpacing: "-0.5px",
        }

        const headingStyleMobile = {
            fontFamily: "CircularStd, Arial, sans-serif",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "2rem",
            padding: "0rem",
            lineHeight: "39px",
            letterSpacing: "-0.5px",
        }

        const paraStyleDesktop = {
            padding: "16px 0 0 0",
            fontSize: "1.5rem",
            letterSpacing: "-0.5px",
        }

        const paraStyleMobile = {
            padding: "16px 0 0 0",
            fontSize: "1rem",
            letterSpacing: "-0.5px",
        }

        return (
            <>
                <div className="home">
                    <Desktop>
                        <h1 style={headingStyleDesktop}>Ready.</h1>
                        <h1 style={headingStyleDesktop}>Set.</h1>
                        <img src={FlickDotLogo} alt="Flick Logo" style={{ width: 174, height: 74 }} />
                        <p style={paraStyleDesktop}>Transfer files across any platform<br />with a simple flick.</p>
                    </Desktop>
                    <Mobile >
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                            <h1 style={headingStyleMobile}> Ready. Set.</h1>
                            <img src={FlickDotLogo} alt="Flick Logo" style={{ height: "1.5rem", margin: "0 10px"}} />
                        </div>
                        <p style={paraStyleMobile}>Transfer files with a simple flick.</p>
                    </Mobile>

                    {/* Download button */}
                    <a href={this.state.platform.link || '#'} onClick={this.state.platform.onLinkClick}><button className="btn-join" style={{ cursor: 'pointer' }}>Download for {this.state.platform.title}</button></a>

                    {/* Beta button */}
                    {this.state.platform.betaLink ?
                        <a href={this.state.platform.betaLink || '#'} onClick={this.state.platform.onBetaLinkClick}><button className="btn-join btn-beta" style={{ cursor: 'pointer' }}>Try the Beta</button></a>
                    : null}

                    <Desktop>
                        <PlatformList platform={this.state.platform} onSelectPlatform={platform => this.setState({ platform })} />
                    </Desktop>
                </div>

            </>
        );
    }
}

export default Home;