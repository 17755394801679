import UAParser from 'ua-parser-js'

// Detect OS to use
export default function detectOS() {

    // Parse user agent
    let ua = UAParser()
    // console.log(ua)
    let version = 'linux'
    if (ua.os.name == 'Android')
        version = 'android'
    else if (ua.os.name == 'iOS')
        version = 'ios'
    else if (ua.os.name == 'Mac OS' && navigator.maxTouchPoints > 2)
        version = 'ios'    // HACK: iPad OS 13 has "desktop mode" turned on by default for all websites now. Thanks, Apple.
    else if (ua.os.name == 'Mac OS')
        version = 'macOS'
    else if (ua.os.name == 'Windows' && ua.os.version >= '10')
        version = 'windowsUniversal'
    else if (ua.os.name == 'Windows')
        version = 'windowsLegacy'
    else
        version = 'linux'

    // Done
    console.log('Detected OS: ' + version)
    return version

}
