import React from 'react';
import PlatformInfo from '../models/Platforms';
import PlatformLogoApple from '../images/platforms/platform_apple.svg'
import PlatformLogoAndroid from '../images/platforms/platform_android.svg'
import PlatformLogoWindows from '../images/platforms/platform_windows.svg'
import PlatformLogoLinux from '../images/platforms/platform_linux.svg'

export default function Platform(props) {

    const availableTextStyle = {
        fontSize: "12px",
        opacity: 0.75,
        padding: "0 0 16px 0",
    }

    const deviceContainer = {
        display: "flex",
        flowDirection: "row",
        flexWarp: "wrap",
        // width: 100,
        // marginRight: "auto",
        justifyContent: "flex-start",
        alignItems: "center",
        // background: "red",
    }

    return (
        <>
            <p style={availableTextStyle}>Available for:</p>
            <div style={deviceContainer}>
                {PlatformInfo.map(platform => 
                    <PlatformIcon key={platform.id} platform={platform} selected={platform == props.platform} onClick={e => props.onSelectPlatform(platform)} />
                )}
            </div>
        </>
    );
}

class PlatformIcon extends React.PureComponent {

    state = {
        hovering: false
    }

    render() {

        return <div>

            {/* Platform icon */}
            <div onClick={this.props.onClick} title={this.props.platform.title} style={{
                marginRight: "1.5rem",
                display: 'inline-block',
                backgroundColor: '#EFF2F5',
                borderRadius: 24,
                borderWidth: 2,
                borderColor: this.props.selected || this.state.hovering ? '#49DAE4' : '#EFF2F5',
                borderStyle: 'solid',
                width: 48, 
                height: 48,
                transition: 'border-color 0.2s',
                cursor: 'pointer'
            }}
            onMouseOver={e => this.setState({ hovering: true })}
            onMouseOut={e => this.setState({ hovering: false })}
            onTouchStart={e => this.setState({ hovering: true })}
            onTouchEnd={e => this.setState({ hovering: false })}
            onTouchCancel={e => this.setState({ hovering: false })}
            >
                <img src={this.props.platform.logo} style={{ 
                    width: 24, 
                    height: 24, 
                    margin: 10, 
                    opacity: this.props.selected || this.state.hovering ? 1.0 : 0.5,
                    transition: 'opacity 0.2s'
                }} />
            </div>

            {/* Platform description text area */}
            <div style={{ 
                width: 400, 
                marginLeft: -200 + 24, 
                marginRight: -200, 
                textAlign: 'center', 
                fontFamily: "CircularStd, Arial, sans-serif", 
                opacity: this.props.selected ? 1.0 : 0.0,
                transition: 'opacity 0.2s'
            }}>
                <div style={{ fontSize: 16,fontWeight: 'bold', paddingTop: 10 }}>{this.props.platform.title}</div>
                <div style={{ fontSize: 10,paddingTop: 2 }}>{this.props.platform.subtitle}</div>
            </div>

        </div>

    }

}